import {ElMessage} from "element-plus";
import router from "@/router";
import moment from "moment";

// IP
export const SERVE_HOST = 'http://weinquiry.top/'

export const SERVICE_INTERVAL_TIME = 10 * 1000 // 服务数据 轮询时间 （s -> ms）
export const ONLINE_INTERVAL_TIME = 60 * 60 * 1000 // 在线时间范围限制 （min -> ms）

// 去除字符串所有空格
export function removeAllSpaces(str) {
    return str.replace(/\s+/g, '');
}

/**
 * 服务异常提醒
 * @param msg String
 */
export function serveError(msg) {
    if (isEmpty(msg))
        msg = "服务错误，请稍后再试！"
    ElMessage.error(msg)
}

/**
 * 服务请求出错，跳转回指定页面
 * @param url string 目标url
 * @param query_obj Object 跳转需要带的参数
 */
export function severErrorAndBack(url, query_obj) {
    serveError()
    openNewWindows(url, query_obj)
}

/**
 * 打开新窗口跳转
 * @param url string 目标url
 * @param queryObj
 */
export function openNewWindows(url, queryObj) {
    const newPage = router.resolve({
        path: url, // url path
        query: queryObj
    })
    window.open(newPage.href, '_blank')
}

// 判断屏幕是否为小屏幕
export function isSmallScreen() {
    let clientWidth = document.documentElement.clientWidth
    // 小屏
    return clientWidth <= 1024;
}

/**
 * 图片路径处理
 * @param url 原始url
 * @returns {*|string} 可请求到的url
 */
export function handleImgUrl(url) {
    if (isEmpty(url))
        return 'http://weinquiry.top/storage/topic/20240403/8509d9ae5602c1dc6ce44afce94bd176.jpeg'

    const reg = /^http(s)?:\/\/(.*?)\//;
    if (reg.test(url))
        return url
    else
        return SERVE_HOST + 'storage/' + url
}

/**
 * 判断值是否为空
 * @param arr
 * @returns {boolean}
 */
export function isEmpty(arr) {

    // 移除字符串中的空格
    function isStringEmpty(inputString) {
        const stringWithoutSpaces = inputString.replace(/\s/g, '');
        return stringWithoutSpaces.length === 0;
    }

    if (arr === "") return true; //检验空字符串
    if (arr === "null") return true; //检验字符串类型的null
    if (arr === "undefined") return true; //检验字符串类型的 undefined
    if (!arr && arr !== 0 && arr !=="") return true; //检验 undefined 和 null

    // Check if the input is a string and if it's empty after removing spaces
    if (typeof arr === 'string' && isStringEmpty(arr)) return true;

    // eslint-disable-next-line no-prototype-builtins
    if (Array.prototype.isPrototypeOf(arr) && arr.length === 0 ) return true; //检验空数组
    // eslint-disable-next-line no-prototype-builtins
    if (Object.prototype.isPrototypeOf(arr) && Object.keys(arr).length === 0 ) return true;  //检验空对象
    return false;
}

/**
 * 判断数据是否为整数
 * @param data
 */
export function isInteger(data) {
    if (typeof data === 'number')
        return Number.isInteger(data)

    if (typeof data === 'string' && data.trim() !== '')
        return Number.isInteger(Number(data))
}

// JSON对象转为连接传输参数
export function getJSONURLParam(jsonObj) {
    let params = ""
    Object.keys(jsonObj).forEach(function (value) {
        let pp = value + "=" + encodeURIComponent(jsonObj[value]) + "&"
        params += pp
    })

    /*if (!isEmpty(params))
        params = "?" + params.substring(0, params.length-1)*/

    return params
}

/**
 * 计算之前的时间 距离 当前 有多远
 * @param prevTime 之前的时间 str ("YYYY-MM-DD HH:MM:SS," "Day Mon DD YYYY HH:MM:SS GMT+XXXX," and a timestamp in milliseconds)
 * @param timeInterval 时间间隔（ms)
 * @returns {boolean} true（范围内）；false（范围外）
 */
export function isInTimeEndByNow(prevTime, timeInterval) {
    const currentTime = moment().valueOf()
    const lastActivityTime = convertTimeStringToTimestamp(prevTime)
    const timeDiff = currentTime - lastActivityTime

    // console.log(`当前时间${new Date()}, 时间戳${currentTime}；`)
    // console.log(`过去时间${prevTime}，时间戳${lastActivityTime}；时间差${timeDiff}, 期待的时间范围${timeInterval};比较后的结果${timeDiff <= timeInterval}`)

    return timeDiff <= timeInterval
}

/**
 * 根据指定时间获取对应的时间戳
 * @param timeString  String ("YYYY-MM-DD HH:MM:SS," "Day Mon DD YYYY HH:MM:SS GMT+XXXX," and a timestamp in milliseconds)
 * @returns {number} 时间戳
 */
export function convertTimeStringToTimestamp(timeString) {

    if (isEmpty(timeString))
        return moment().valueOf()

    if (!isNaN(timeString) && !isNaN(parseFloat(timeString))) {
        return Number(timeString);
    }

    // Assuming the timeString is in UTC for consistency
    const timestamp = moment(timeString, 'YYYY-MM-DD HH:mm:ss').valueOf();
    return !isNaN(timestamp) ? timestamp : moment().valueOf();
}

/**
 * 将字符串时间转换为 YYYY-MM-DD HH:MM
 * @param timeString
 * @returns {string}
 */
export function convertStringToTime(timeString) {
    let dateVal = new Date(timeString)

    let year = dateVal.getFullYear();
    let month = _formatNum(dateVal.getMonth() + 1);
    let day = _formatNum(dateVal.getDate());
    let hour = _formatNum(dateVal.getHours());
    let minute = _formatNum(dateVal.getMinutes())

    function _formatNum(val) {
        return val < 10 ? '0'+val : val
    }

    return `${year}-${month}-${day} ${hour}:${minute}`
}

/**
 * 生成指定范围的随机数
 * @param max 最大值 or 一个值
 * @param min 最小值
 * @returns {number}
 */
export function getRandomInRange(max, min=0) {
    return  parseInt(Math.random() * (max - min + 1) + min,10)
}

const debounce = (fn, delay) => {
    let timer = null;

    return function () {
        let context = this;

        let args = arguments;

        clearTimeout(timer);

        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

/**
 * 检查图片类型/大小，并压缩
 * @param file
 * @returns {Promise<unknown>|boolean}
 */
export function checkAndCompressImg(file) {
    return new Promise((resolve, reject) => {
        const fileName = file.name
        let match = fileName.match(/\.(\w+)$/)
        let fileType = (match ? match[1] : '').toLowerCase();
        let allowedTypes = ['jpg', 'jpeg', 'png']

        if (!allowedTypes.includes(fileType)) {
            ElMessage.error("请选择image/*类型的图片文件 (*.jpg, *.jpeg, *.png)");
            reject(new Error("Invalid image type."));
            return;
        }

        // Validate the file size
        const maxSizeMB = 5;
        const fileSizeMB = file.size / 1024 / 1024;
        if (fileSizeMB > maxSizeMB) {
            ElMessage.error(`上传的图片大小不能超过${maxSizeMB}MB`);
            reject(new Error("Image size is too large."));
            return;
        }

        console.log('压缩前，图片大小', fileSizeMB)

        // Check if the image needs to be compressed
        const compressionThreshold = 0.5; // 0.5MB
        if (fileSizeMB > compressionThreshold) {
            // Compress the image
            compressImg(file, (newFile) => {
                console.log('压缩后，图片大小', newFile.size);
                resolve(newFile);
            });
        } else {
            // Image does not need compression, resolve with the original file
            resolve(file);
        }
    });
}

/**
 * 图片压缩
 * @param img
 * @param callback
 */
export function compressImg(img, callback, quality = 0.8) {
    let reader = new FileReader()
    // let self = this
    reader.readAsDataURL(img);
    reader.onload = function () {
        let image = new Image()
        image.src = this.result // 图片转化为base64 字符串

        image.onload = function () {
            let maxWidth = 800
            let maxHeight = 1200
            let expectWidth = this.naturalWidth
            let expectHeight = this.naturalHeight

            // 更新压缩后图片的大小
            if (expectWidth > maxWidth) {
                expectHeight = expectHeight * (maxWidth / expectWidth);
                expectWidth = maxWidth;
            }
            if (expectHeight > maxHeight) {
                expectWidth = expectWidth * (maxHeight / expectHeight);
                expectHeight = maxHeight;
            }

            let canvas = document.createElement('canvas')
            let ctx = canvas.getContext('2d')
            canvas.width = expectWidth
            canvas.height = expectHeight

            try {
                ctx.drawImage(this, 0, 0, expectWidth, expectHeight);
            } catch (error) {
                console.error('Error drawing image on canvas:', error);
                callback(img); // Fallback to original image
                return;
            }

            // Convert canvas to blob
            canvas.toBlob((blob) => {
                if (!blob) {
                    // Fallback to original image if blob creation failed
                    callback(img);
                    return;
                }
                // Create a new file from the blob
                let newFile = new File([blob], img.name, {
                    type: 'image/jpeg'
                });
                newFile.uid = img.uid; // Copy the uid from the original file
                callback(newFile);
            }, 'image/jpeg', quality);
        };
    };
    reader.onerror = function (error) {
        console.error('Error during file reading:', error);
        callback(img)
    }
}

/**
 * 异步执行debounce
 * @param func
 * @param delay
 * @returns {function(...[*]): Promise<unknown>}
 */
export function debounceFunAsync(func, delay=2000) {
    let debounceTimer;
    return async function(...args) {
        const context = this
        clearTimeout(debounceTimer)
        return new Promise((resolve, reject) => {
            debounceTimer = setTimeout(async () => {
                try {
                    const result = await func.apply(context, args)
                    resolve(result)
                } catch (err) {
                    reject(err)
                }
            }, delay)
        })
    }
}


const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};

