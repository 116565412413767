import axios from 'axios'
import {ElMessage} from 'element-plus'
// import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {serveError} from "@/utils/operation";
import router from "@/router";

axios.defaults.baseURL = 'http://weinquiry.top/'
axios.defaults.headers.post['Content-Type'] = 'application/json'

// NProgress.inc(0.2)
// NProgress.configure({ easing: 'ease', speed: 600, showSpinner: false })

const NEED_JUMP_LINK = ['/login', '/user/logout'] // 发生错误需要跳转回登录页面的link

axios.interceptors.request.use(config => {
    // NProgress.start() // 启动进度条
    config.metadata = {startTime: new Date()} // 开始请求时间
    return config
}, error => {
    // NProgress.done()
    return Promise.reject(error)
})

axios.interceptors.response.use(response => {

    response.config.metadata.endTime = new Date() // 请求响应时间
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime

    // NProgress.done() // 关闭进度条

    const data = response.data

    if (data.ret === 1) { // 未登录
        if (!response.config.url.endsWith('studentList'))
            jumpToLoginFile()
        return Promise.reject('用户未登陆！')
    } else if (data.ret === 2) { // 用户信息不存在
        // ElMessage.error('用户信息不存在，请联系授课教师添加账号！')
        return Promise.reject('用户信息不存在！')
    } else if (data.ret !== '0' && data.ret !== 0) { // 其他错误
        if (NEED_JUMP_LINK.includes(response.config.url)) {
            // jumpToLoginFile()
        }
        ElMessage.error(data.msg || '服务出错了，请稍后再试！')
        return Promise.reject(data.msg || '服务请求出错')
    }

    return data.data
}, error => {
    // NProgress.done() // 关闭进度条

    if (error.response) {
        let {status, data} = error.response;
        if (status >= 400) {
            serveError(data.msg)
        }
    } else {
        serveError(null)
    }
    return Promise.reject(error);
})


function jumpToLoginFile() {
    const fullPath = router.currentRoute.value.fullPath
    const isStudent = /\bstudent\b/.test(fullPath)
    const pathUrl = isStudent ? '/student/login' : '/teacher/login'

    const match = fullPath.match(/[?&]id=(\d+)/)
    const id = match ? match[1] : null
    const query = isStudent ? {id} : {}

    router.push({path: pathUrl, query})
}

export default axios