import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {postStageTime} from "@/serve/student";
import {routes} from "@/router/routes";


const router = createRouter({
    history: createWebHashHistory(),
    routes
})

NProgress.inc(0.2)
NProgress.configure({ easing: 'ease', speed: 600, showSpinner: false })

// 用户访问时间
let startTime = null;
let reportingInterval = null;
// Monitor time spent on specific routes
const monitoredRoutes = ['/student/brainstorm', '/student/exploration', '/student/discussion'];

// report time
function reportTime(route, timeSpent, query) {
    const groupid = query.group || 1
    const stageid = query.stage || 1

    // Report the time spent
    postStageTime({
        activityid: query.id,
        groupid: groupid,
        stageid: stageid,
        time: timeSpent / 1000 // 单位：second
    })
}

// Store time spent in localStorage for persistence
function storeTimeInLocalStorage(routePath, timeSpent, query) {
    const data = {
        path: routePath,
        timeSpent,
        query,
        timestamp: Date.now()
    };
    localStorage.setItem('unsavedTimeData', JSON.stringify(data));
}

// Retrieve and report any unsaved time data
function handleUnsavedTimeData() {
    const unsavedData = localStorage.getItem('unsavedTimeData');
    if (unsavedData) {
        const { path, timeSpent, query } = JSON.parse(unsavedData);
        reportTime(path, timeSpent, query);
        localStorage.removeItem('unsavedTimeData');
    }
}

// 报告时间 有操作，2min报一次
function startReportingInterval(route, query) {
    if (reportingInterval) clearInterval(reportingInterval);

    reportingInterval = setInterval(() => {
        const currentTime = Date.now();
        const timeSpent = currentTime - startTime;
        startTime = currentTime; // Reset start time for the next interval

        reportTime(route.path, timeSpent, query);
        storeTimeInLocalStorage(route.path, timeSpent, query);
    }, 2 * 60 * 1000); // 2 minutes
}

// 设置title
router.beforeEach((to, from, next) => {
    // 启动进度条
    NProgress.start()

    // 设置头部
    if (to.meta.title) {
        document.title = to.meta.title
    } else {
        document.title = "协作探究学习"
    }

    // 记录用户页面访问时间
    handleUnsavedTimeData();  // Handle any unsaved data on navigation

    // 原本有时间，直接报告
    if (monitoredRoutes.includes(from.path) && startTime) {
        const endTime = Date.now();
        const timeSpent = endTime - startTime;
        reportTime(from.path, timeSpent, from.query);
        storeTimeInLocalStorage(from.path, timeSpent, from.query);
    }

    // 循环报告
    if (monitoredRoutes.includes(to.path)) {
        startTime = Date.now();
        startReportingInterval(to, to.query);
    } else {
        if (reportingInterval) clearInterval(reportingInterval);
        startTime = null;
    }

    // 页面活动
    next()
})

// 用户离开页面，报告数据
window.addEventListener('beforeunload', () => {
    if (monitoredRoutes.includes(router.currentRoute.path) && startTime) {
        const endTime = Date.now();
        const timeSpent = endTime - startTime;

        reportTime(router.currentRoute.path, timeSpent, router.currentRoute.query);
        storeTimeInLocalStorage(router.currentRoute.path, timeSpent, router.currentRoute.query);
    }
});

router.afterEach(() => {
    // 关闭进度条
    NProgress.done()
})

export default router
