export const routes = [
    {
        path: '',
        redirect: '/teacher/login'
    },
    {
        path: '/',
        redirect: '/teacher/login'
    },
    {
        path: '/teacher',
        redirect: '/teacher/login'
    },
    {
        path: '/student',
        redirect: '/student/login'
    },
    {
        path: '/student/login',
        component: () => import('@/views/Student/Login.vue'),
        meta: {
            title: '协作探究学习'
        },
    },
    {
        path: '/teacher/login',
        component: () => import('@/views/Teacher/Login.vue'),
        meta: {
            title: '协作探究学习'
        }
    },
    {
        path: '',
        component: () => import('@/views/Student.vue'),
        children: [
            {
                path: '/student/brainstorm',
                name: 'Brainstorm',
                component: () => import('@/views/Student/Brainstorm.vue'),
                meta: {
                    title: '协作探究学习',
                    index: 1
                }
            },
            {
                path: '/student/exploration',
                name: 'Exploration',
                component: () => import('@/views/Student/Exploration.vue'),
                meta: {
                    title: '协作探究学习',
                    index: 2
                }
            },
            {
                path: '/student/discussion',
                name: 'Discussion',
                component: () => import('@/views/Student/Discussion.vue'),
                meta: {
                    title: '协作探究学习',
                    index: 3
                }
            },
        ]
    },
    {
        path: '',
        component: () => import('@/views/TeacherPage.vue'),
        children: [
            {
                path: '/teacher/create',
                name: 'Create',
                component: () => import('@/views/Teacher/Create.vue'),
                meta: {
                    title: '创建活动',
                    index: 1
                }
            },
            {
                path: '/teacher/analysis1', // 面向教师的数据分析（完整版）
                name: 'VisualComplex',
                component: () => import('@/views/Visualization/Visualization.vue'),
                meta: {
                    title: '数据分析（教师）',
                    index: 3
                }
            },
            {
                path: '/teacher/analysis', // 面向教师的数据分析（简化版）
                name: 'VisualSimple',
                component: () => import('@/views/Visualization/VisualSimple.vue'),
                meta: {
                    title: '数据分析（教师）',
                    index: 2
                }
            },
        ]
    },
    {
        path: '/teacher/eachanalysis', // 给学生的分析结果(课堂分析）,每个个体单独呈现
        name: 'VisualStudentForEach',
        component: () => import('@/views/Visualization/VisualStudentEach.vue'),
        meta: {
            title: '数据分析（学生）',
            index: 4
        }
    },
    {
        path: '',
        component: () => import('@/views/Teacher.vue'),
        children: [
            {
                path: '/teacher/course',
                name: 'Course',
                component: () => import('@/views/Teacher/CourseList.vue'),
                meta: {
                    title: '课程活动列表',
                    index: 2
                }
            },
            {
                path: '/teacher/class',
                name: 'stuList',
                component: () => import('@/views/Teacher/StuList.vue'),
                meta: {
                    title: '学生列表',
                    index: 4
                }
            }
        ]
    }
]