import axios from "@/utils/axios"
import {getStudentToken} from "@/utils/student"

// 学生登录 - 已完成
// /login?name=test&pwd=E10ADC3949BA59ABBE56E057F20F883E
export function studentLogin(params) {
    return axios.post('/login', params)
}

// 学生退出登录 - 结果返回格式错误
// 登出：/user/logout
export function studentLoginOut() {
    let params = {}
    params['token'] = getStudentToken()
    return axios.post('/user/logout', params)
}

// 当前小组的在线用户列表 - 需要明确是否是在线用户列表
// {"ret":2,"msg":"登录名重复"}  /activity/studentList?activityid=1&groupid=1&length=0&page=1
// 根据学生的登录时间，判断学生是否在线 （studentList加了个updatetime的字段）
export function getOnlineUser(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity/studentList', params)
}

// 活动详情 - 已完成
// /user/activity?id=1
// ai: 0, 1 是否显示gpt功能
export function getCourseDetailById(params) {
    params['token'] = getStudentToken()
    return axios.post('/user/activity', params)
}

// 活动详情 - 不用登录版
// /user/activity?id=1
export function getCourseDetailByIdAnony(params) {
    return axios.post('/anony', params)
}

// 学生切换stage时调用，仅用于告诉后台，切换了stage
// /activity/list?activityid=1&groupid=1&stageid=1
// 暂时弃用这一个，我有自己上报用户时间的方法
export function changeActivityStage(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity', params)
}

// 上报学生每阶段的活动时间
// /activity/addtimelog?activityid=11&stageid=2&token=2_3CFFEE8F024DCB2BB62CDA371856FE02&groupid=2&time=3
export function postStageTime(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity/addtimelog', params)
}

// 上报每个stage, 学生之间的相互访问情况
// activity/addrelate
// activityid, groupid
// targetid 被访问的学生id
// time 总共的访问时长
export function postStageInteraction(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity/addrelate', params)
}

// ---------------------------1.头脑风暴-----------------------
// 提交头脑风暴的内容 - 已完成
// 加个参数content就是发表想法：/activity?activityid=1&groupid=1&stageid=1&content=134234234234
// 针对特定的讨论内容 点赞、反对
// /activity?activityid=1&groupid=1&stageid=1&content1=3434234&type=1 这个我加了几个字段
// content1表示证据，content2表示理由，type=1是赞成，type=2是反对，contentid就是要评论的id
// category : 信息类型
export function postBrainstormContent(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity', params)
}

// 查询头脑风暴的讨论列表
// 查询观点列表：/activity/list?activityid=1&groupid=1&stageid=1
export function getBrainstormDiscussion(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity/list', params)
}

// ----------------------------2.协作探索--------------------------------
// 查询头脑风暴的讨论列表
// 查询观点列表：/activity/list?activityid=1&groupid=1&stageid=1
export function getCollaborativeDiscussion(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity/list', params)
}

// 提交学生笔记
// 加个参数content就是发表想法：/activity?activityid=1&groupid=1&stageid=1&content=134234234234
// 针对特定的讨论内容 点赞、反对
// picurl 图片参数
// /activity?activityid=1&groupid=1&stageid=1&content1=3434234&type=1 这个我加了几个字段content1表示证据，content2表示理由，type=1是赞成，type=2是反对，type=0 评论， contentid就是要评论的id
export function postDiscussionContent(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity', params)
}

// 学生笔记列表
// 小组讨论（exploration）：/activity/grouplist?activityid=1&groupid=1
export function getCollaborativeContent(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity/grouplist', params)
}

// 统计学生的资源使用情况
// 资源使用 /activity/addresource?activityid=12&groupid=1&source=2&target=1&time=10
export function uploadResourceUse(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity/addresource', params)
}

// ----------------------------3.圆桌讨论--------------------------------
// 查询头脑风暴的讨论列表
// 查询观点列表：/activity/list?activityid=1&groupid=1&stageid=1
// 跟笔记相关的核心观点
export function getGroupOpinion(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity/list', params)
}

// 学生笔记列表
// 小组讨论（exploration）：/activity/grouplist?activityid=1&groupid=1
export function getGroupNotes(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity/grouplist', params)
}

// 提交在线讨论内容
// 针对特定的讨论内容 点赞、反对
// picurl 图片参数
// /activity?activityid=1&groupid=1&stageid=1&content1=3434234&type=1 这个我加了几个字段content1表示证据，content2表示理由，type=1是赞成，type=2是反对，type=0 评论， contentid就是要评论的id
// 证据对应content1 理由对应content2
export function postForumContent(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity', params)
}

// 获取在线讨论列表
// 小组讨论（exploration）：/activity/grouplist?activityid=1&groupid=1
export function getDiscussionList(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity/grouplist', params)
}

// 根据contentid 获取讨论内容 (子内容）
// 同样列表也可以加个contentid过滤：/activity/list?activityid=1&groupid=1&stageid=1&contentid=1
export function getDiscussionContentById(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity/list', params)
}

// 在线讨论，点赞/回复
// 加个参数content就是发表想法：/activity?activityid=1&groupid=1&stageid=1&content=134234234234
// 针对特定的讨论内容 点赞、反对
// /activity?activityid=1&groupid=1&stageid=1&content1=3434234&type=1 这个我加了几个字段content1表示证据，content2表示理由，type=1是赞成，type=2是反对，contentid就是要评论的id
export function AgreeDiscussionContent(params) {
    params['token'] = getStudentToken()
    return axios.post('/activity', params)
}