import { createApp } from 'vue'

import {
    ElButton, ElTabs, ElDialog, ElMessage, ElTooltip, ElEmpty, ElPagination,
    ElRow, ElCol, ElCard, ElForm, ElInput, ElInputNumber, ElSelect, ElOption,
    ElTable, ElTableColumn, ElScrollbar, ElImage} from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/base.css'

import App from './App.vue'
import router from "@/router"

const app = createApp(App)

app.use(ElButton)
    .use(ElTabs)
    .use(ElDialog)
    .use(ElMessage)
    .use(ElTooltip)
    .use(ElEmpty)
    .use(ElPagination)
    .use(ElRow)
    .use(ElCol)
    .use(ElCard)
    .use(ElForm)
    .use(ElInput)
    .use(ElInputNumber)
    .use(ElSelect)
    .use(ElOption)
    .use(ElTable)
    .use(ElTableColumn)
    .use(ElScrollbar)
    .use(ElImage)


app.use(router)

app.mount('#app')

